<template>
    <div class="page">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :title="$t('在线服务')" left-arrow
                     @click-left="onClickLeft">
        </van-nav-bar>
        <div class="wrapper" style="font-size: 14px;padding: 0" v-html="html">
            <!--			<div class="cust-card">-->
            <!--				<div class="cust-hd">{{$t('在线服务')}}</div>-->
            <!--				<div class="cust-bd">-->
            <!--					{{$t('客服描述')}}-->
            <!--				</div>-->
            <!--				<div class="cust-link">-->
            <!--					点击联系客服dianjilianxikefu-->
            <!--				</div>-->
            <!--			</div>-->
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                active: '',
                show: false,
                html: '',
            }
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1)
            },
            getData() {
                this.$axios
                    .get('/help/get_content?type=13')
                    .then((res) => {
                        this.html = res.data;
                    });
            }
        },
        mounted() {
            this.getData();
        }

    }
</script>
